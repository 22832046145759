import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { ColorPalette } from 'Constants/Styles';
import { DnDListItem } from 'Components/Primitives/DnD/DnD.types';
import DnDElement from 'Components/Primitives/DnD/DnDElement';
import { ElementDragPayload, monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { reorder } from '@atlaskit/pragmatic-drag-and-drop/reorder';
import { getReorderDestinationIndex } from '@atlaskit/pragmatic-drag-and-drop-hitbox/util/get-reorder-destination-index';
import { extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { DragLocationHistory } from '@atlaskit/pragmatic-drag-and-drop/dist/types/entry-point/types';

export interface DnDProps {
  list: DnDListItem[];
  onOrderChange?(orderedList: DnDListItem[]): void;
}

const StyledDnd = styled.ul`
  > li {
    position: relative;
    &.dragging {
      opacity: 0.4;
    }
  }
  /* Drop indicator styling */
  .drop-indicator {
    position: absolute;
    z-index: 10;
    background-color: ${ColorPalette.bellBlue60};
    pointer-events: none;
    box-sizing: border-box;
    height: 1px;
    left: 0;
    right: 0;

    /* Small circle styling */
    &::before {
      content: '';
      width: 8px;
      height: 8px;
      position: absolute;
      border: 1px solid ${ColorPalette.bellBlue60};
      border-radius: 50%;
      top: -4px;
      left: -8px;
    }

    &.edge-top {
      top: -0.5px;
    }

    &.edge-bottom {
      bottom: -0.5px;
    }
  }
`;

export const DnD: React.FC<DnDProps> = ({ list: initialList, onOrderChange }) => {
  const [list, setList] = useState<DnDListItem[]>(initialList);

  const reorderElements = useCallback(
    (params: { startIndex: number; finishIndex: number }) => {
      const { startIndex, finishIndex } = params;
      const updatedItems = reorder({ list, startIndex, finishIndex });
      setList(updatedItems);
      //
      onOrderChange && onOrderChange(updatedItems);
    },
    [list, onOrderChange]
  );

  const handleDrop = useCallback(
    (params: { source: ElementDragPayload; location: DragLocationHistory }) => {
      const { source, location } = params;
      const destination = location.current.dropTargets[0];
      if (!destination) return;
      if (source.data.type === 'dndListItem') {
        const draggedElementId = source.data.listElementKey;
        const destinationElementId = destination.data.listElementKey;

        const draggedCardIndex = list.findIndex((li) => li.key === draggedElementId);
        const destinationCardIndex = list.findIndex((li) => li.key === destinationElementId);
        const closestEdgeOfTarget = extractClosestEdge(destination.data);

        if (location.current.dropTargets.length === 1) {
          const destinationIndex = getReorderDestinationIndex({
            startIndex: draggedCardIndex,
            indexOfTarget: destinationCardIndex,
            closestEdgeOfTarget,
            axis: 'vertical'
          });

          reorderElements({
            startIndex: draggedCardIndex,
            finishIndex: destinationIndex
          });
          return;
        }
      }
    },
    [list, reorderElements]
  );

  useEffect(() => {
    return monitorForElements({
      onDrop: handleDrop
    });
  }, [handleDrop]);

  useEffect(() => {
    setList(initialList);
  }, [initialList]);

  return (
    <StyledDnd className="dnd">
      {list.map((li) => (
        <DnDElement key={li.key} listItem={li} />
      ))}
    </StyledDnd>
  );
};
