import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import DropIndicator from 'Components/Primitives/DnD/DropIndicator';
import { DnDListItem } from 'Components/Primitives/DnD/DnD.types';
import { draggable, dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { attachClosestEdge, Edge, extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';

interface DnDListItemProps {
  listItem: DnDListItem;
}

const DnDElement: React.FC<DnDListItemProps> = ({ listItem }) => {
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [closestEdge, setClosestEdge] = useState<Edge | null>(null); // NEW
  const ref = useRef<HTMLLIElement | null>(null);

  useEffect(() => {
    if (!ref.current) return;
    const listElement = ref.current;

    return combine(
      draggable({
        element: listElement,
        getInitialData: () => ({ type: 'dndListItem', listElementKey: listItem.key }),
        onDragStart: () => setIsDragging(true),
        onDrop: () => setIsDragging(false)
      }),

      dropTargetForElements({
        element: listElement,
        getData: ({ input, element }) => {
          const data = { type: 'dndListItem', listElementKey: listItem.key };

          return attachClosestEdge(data, {
            input,
            element,
            allowedEdges: ['top', 'bottom']
          });
        },
        getIsSticky: () => true,
        onDragEnter: (args) => {
          if (args.source.data.listElementKey !== listItem.key) {
            setClosestEdge(extractClosestEdge(args.self.data));
          }
        },
        onDrag: (args) => {
          if (args.source.data.listElementKey !== listItem.key) {
            setClosestEdge(extractClosestEdge(args.self.data));
          }
        },
        onDragLeave: () => {
          setClosestEdge(null);
        },
        onDrop: () => {
          setClosestEdge(null);
        }
      })
    );
  }, [listItem.key, ref]);

  return (
    <li
      ref={ref}
      data-testid={listItem.element.dataTestId}
      className={classNames(listItem.element.classNames, isDragging ? 'dragging' : '')}
    >
      {listItem.element.content}
      {closestEdge && <DropIndicator edge={closestEdge} gap={8} />}
    </li>
  );
};

export default DnDElement;
