import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import isEqual from 'fast-deep-equal';
import { Input } from 'Components/Primitives/Input/Input';
import { Checkbox, CheckboxChangeEvent } from 'Components/Primitives';
import { Button } from 'Components/Primitives/Button/Button';
import { DnD, DnDListItem, Icon, Icons } from 'Components/Primitives';
import { CheckboxSelectOption } from 'Components/CheckboxSelect/CheckboxSelect.types';
import { StyledCheckboxSelect } from 'Components/CheckboxSelect/StyledCheckboxSelect';

export interface CheckboxSelectDnDProps {
  options: CheckboxSelectOption[];
  value?: (string | number)[];
  order?: (string | number)[] | undefined;
  defaultValue?: (string | number)[] | undefined;
  defaultOrder?: (string | number)[] | undefined;
  searchPlaceholder?: string;
  style?: CSSProperties;
  showAllOption?: boolean;
  disabled?: boolean;
  className?: string;
  'data-testid'?: string;
  onChange?(values: (string | number)[]): void;
  onOrderChange?(sortedOptionsKyes: (string | number)[]): void;
}

export const CheckboxSelectDnD: React.FC<CheckboxSelectDnDProps> = ({
  options,
  value,
  defaultValue,
  order = options.map((option) => option.value),
  defaultOrder = options.map((option) => option.value),
  searchPlaceholder,
  showAllOption = true,
  disabled,
  style,
  className,
  'data-testid': dataTestId = 'checkbox-select',
  onChange,
  onOrderChange
}) => {
  const { t } = useTranslation(['common']);
  const [searchVal, setSearchVal] = useState<string>();
  const [checkedList, setCheckedList] = useState<(string | number)[]>(value || []);

  const [indeterminate, setIndeterminate] = useState(value && value.length > 0);
  const [checkAll, setCheckAll] = useState(value && value.length === options.length);

  const [indeterminateList, setIndeterminateList] = useState(false);
  const [checkAllList, setCheckAllList] = useState(false);

  const [dragMode, setDragMode] = useState<boolean>(false);
  const [sortedOptions, setSortedOptions] = useState<CheckboxSelectOption[]>([]);

  const visibleOptionsValues = useMemo((): (string | number)[] => {
    return searchVal && searchVal.length > 0
      ? [...sortedOptions]
          .filter((option) => option.searchValue.toLowerCase().includes(searchVal?.toLocaleLowerCase()))
          .map((o) => o.value)
      : [...sortedOptions].map((o) => o.value);
  }, [sortedOptions, searchVal]);

  const isResetDisabled = useMemo((): boolean => {
    return defaultValue !== undefined
      ? [...defaultValue].sort().join(',') === [...checkedList].sort().join(',') &&
          isEqual(
            sortedOptions.map((option) => option.value),
            defaultOrder
          )
      : false;
  }, [defaultValue, checkedList, sortedOptions, defaultOrder]);

  const dndList = useMemo(
    (): DnDListItem[] =>
      sortedOptions.map((option) => ({
        key: option.value,
        element: {
          content: (
            <>
              {dragMode ? (
                <div className="draggable-option">
                  <Icon className="draggable-option-icon" icon={Icons.DragNDrop} />{' '}
                  <span className="draggable-option-label">{option.label}</span>
                </div>
              ) : (
                <Checkbox className="checkbox-select-option" data-testid="checkbox-select-option" value={option.value}>
                  {option.label}
                </Checkbox>
              )}
            </>
          ),
          classNames: ['checkbox-select-option-wrapper'].concat(
            visibleOptionsValues.includes(option.value) ? ['visible'] : ['hidden']
          ),
          dataTestId: 'checkbox-select-option-wrapper'
        }
      })),
    [sortedOptions, visibleOptionsValues, dragMode]
  );

  const onGroupChange = useCallback(
    (list: (string | number)[]) => {
      setCheckedList(list as (string | number)[]);
      onChange && onChange(list as (string | number)[]);
    },
    [onChange]
  );

  const onCheckAllChange = useCallback(
    (e: CheckboxChangeEvent) => {
      const list = e.target.checked ? options.map((o) => o.value) : [];
      setCheckedList(list);
      onChange && onChange(list);
    },
    [onChange, options]
  );

  const onCheckListChange = useCallback(
    (e: CheckboxChangeEvent) => {
      const list = e.target.checked
        ? [...checkedList, ...visibleOptionsValues]
        : checkedList.filter((v) => !visibleOptionsValues.includes(v));
      setCheckedList(list);
      onChange && onChange(list);
    },
    [onChange, checkedList, visibleOptionsValues]
  );

  const onResetClick = useCallback(() => {
    // reset selection
    const list = options.filter((o) => (defaultValue || []).includes(o.value)).map((o) => o.value);
    setCheckedList(list);
    onChange && onChange(list);

    // reset order
    const sorted = [...options].sort((a, b) => defaultOrder.indexOf(a.value) - defaultOrder.indexOf(b.value));
    setSortedOptions(sorted);
    onOrderChange && onOrderChange(defaultOrder);

    // reset search
    setSearchVal('');
  }, [options, defaultValue, defaultOrder, onChange, onOrderChange]);

  const onDnDOrderChange = useCallback(
    (orderedList: DnDListItem[]) => {
      const orderdKeys = orderedList.map((listItem) => listItem.key);
      setSortedOptions([...options].sort((a, b) => orderdKeys.indexOf(a.value) - orderdKeys.indexOf(b.value)));
      onOrderChange && onOrderChange(orderdKeys);
    },
    [onOrderChange, options]
  );

  useEffect(() => {
    const allValuesSelected = checkedList.length === options.length;
    const someValuesSelected = checkedList.length > 0 && checkedList.length < options.length;

    const allVisibleSelected = visibleOptionsValues.every((v) => checkedList.includes(v));
    const someVisibleSelected = visibleOptionsValues.some((v) => checkedList.includes(v));

    setCheckAll(allValuesSelected);
    setIndeterminate(someValuesSelected);

    setCheckAllList(visibleOptionsValues.length > 0 && allVisibleSelected);
    setIndeterminateList(visibleOptionsValues.length > 0 && someVisibleSelected && !allVisibleSelected);
  }, [checkedList, options, visibleOptionsValues]);

  useEffect(() => {
    const sorted = [...options.filter((o) => order.includes(o.value))]
      .sort((a, b) => order.indexOf(a.value) - order.indexOf(b.value))
      .concat([...options.filter((o) => !order.includes(o.value))]);
    setSortedOptions((cur) =>
      isEqual(
        cur.map((o) => o.value),
        sorted.map((o) => o.value)
      )
        ? cur
        : sorted
    );
  }, [options, order]);

  useEffect(() => {
    value && setCheckedList(value);
  }, [value]);

  return (
    <StyledCheckboxSelect className={className} style={style} data-testid={dataTestId}>
      <div className="checkbox-select-header">
        {options.length > 10 && (
          <div className="checkbox-select-search">
            <Input
              data-testid="checkbox-select-search-input"
              autoFocus={true}
              value={searchVal}
              placeholder={searchPlaceholder || t('search')}
              size="middle"
              onChange={(e) => setSearchVal(e.target.value)}
              allowClear={true}
            />
          </div>
        )}

        {showAllOption && (
          <div className={classNames('checkbox-select-all-wrapper', searchVal && searchVal.length > 0 && 'filtered')}>
            <Checkbox
              data-testid="checkbox-select-all"
              className="checkbox-select-option select-all-cb"
              onChange={onCheckAllChange}
              indeterminate={indeterminate}
              checked={checkAll}
              disabled={dragMode}
            >
              {t('all')}
            </Checkbox>
            <Checkbox
              data-testid="checkbox-select-matching"
              className="checkbox-select-option select-matching-cb"
              onChange={onCheckListChange}
              indeterminate={indeterminateList}
              checked={checkAllList}
              disabled={visibleOptionsValues.length === 0 || dragMode}
            >
              {t('all-matching')}
            </Checkbox>

            {checkedList && <div className="total-selected">Selected ({checkedList.length})</div>}
          </div>
        )}
      </div>

      <div className="checkbox-select-list">
        <Checkbox.Group
          defaultValue={value}
          value={checkedList}
          onChange={onGroupChange}
          data-testid="checkbox-select-group"
          disabled={disabled}
        >
          <DnD list={dndList} onOrderChange={onDnDOrderChange} />
        </Checkbox.Group>
      </div>

      {(defaultValue || onOrderChange) && (
        <div className="footer">
          {defaultValue && (
            <Button type="link" data-testid="reset-to-default-button" onClick={onResetClick} disabled={isResetDisabled}>
              {t('reset')}
            </Button>
          )}
          {onOrderChange && (
            <Button type="link" data-testid="reorder-done-button" onClick={() => setDragMode((dm) => !dm)}>
              {dragMode ? t('select') : t('reorder')}
            </Button>
          )}
        </div>
      )}
    </StyledCheckboxSelect>
  );
};
