import styled from '@emotion/styled';
import { Colors, times8 } from 'Constants/Styles';

export const StyledCheckboxSelect = styled.div`
  position: relative;

  .checkbox-select-header,
  .footer {
    position: sticky;
    z-index: 3;
    background-color: white;
  }

  .checkbox-select-header {
    top: 0;
  }

  .checkbox-select-search {
    padding: ${times8(2)}px 0 0;
  }

  .checkbox-select-list {
    padding: ${times8(2)}px 0;
  }

  .checkbox-select-all-wrapper {
    position: relative;
    border-bottom: 1px solid #e6e7e8;
    height: 48px;
    .select-all-cb,
    .select-matching-cb,
    .total-selected {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      margin-inline-start: 0;
      transition:
        opacity 0.2s ease-out,
        transform 0.2s ease-out;
    }
    .select-all-cb {
      opacity: 1;
      transform: translateY(-50%);
      pointer-events: auto;
    }
    .select-matching-cb {
      opacity: 0;
      transform: translateY(0);
      pointer-events: none;
    }
    &.filtered {
      .select-all-cb {
        opacity: 0;
        transform: translateY(-100%);
        pointer-events: none;
      }
      .select-matching-cb {
        opacity: 1;
        transform: translateY(-50%);
        pointer-events: auto;
      }
    }
    .total-selected {
      right: 0;
      left: auto;
      color: ${Colors.SubText};
      transform: translateY(-50%);
    }
  }

  .checkbox-select-option-wrapper {
    padding: ${times8(0.5)}px 0;
    &.visible {
      display: block;
    }
    &.hidden {
      display: none;
    }
    &.dragging {
      background-color: white;
    }
  }

  .checkbox-select-option,
  .draggable-option {
    display: flex;
    align-items: center;
    line-height: 1;
  }

  .checkbox-select-option {
    .ant-checkbox {
      top: unset;
    }
  }

  .draggable-option {
    .draggable-option-icon {
      display: block;
      text-align: center;
      width: 16px;
      height: 16px;
      color: ${Colors.SubText};
      svg {
        display: block;
      }
    }
    .draggable-option-label {
      padding: 0 8px;
    }
  }

  .footer {
    border-top: 1px solid ${Colors.Border};
    padding: ${times8()}px 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
  }
`;
