import {
  convertPaginationToGetRange,
  convertDateRangeValueToQueryDateRange,
  QueryPagination
} from 'Components/Filters';
import { FilterMetadataApiKeys } from 'Components/Filters/FilterApi.consts';
import { messageDuration, useMessage } from 'Components/Primitives';
import { UserWeeklyDigestData } from 'Components/Users/UserWeeklyDigest/UserWeeklyDigest.types';
import { QueryKey } from 'Constants/QueryKeys.enum';
import { backendApi } from 'http/Request';
import { QueryObserverOptions, UseQueryResult, useQuery } from 'react-query';
import { DateRangeValue } from 'Types/DateRange.types';
import { ActiveInactive } from 'Types/Shared.types';
import { User, UserEssentials, UserOverview, UserStatistics } from 'Types/User.types';
import { useCustomReportDataQuery } from 'hooks/QueryHooks/useCustomReportQuery';
import { ReportRefEntityType } from 'Types/ReportField.types';
import { CustomReportData } from 'Types/Reports.types';

//
// useUsersQuery
export const useUsersQuery = (options?: QueryObserverOptions<User[]>) => {
  const message = useMessage();

  return useQuery<User[]>(
    [QueryKey.Users],
    () =>
      backendApi
        .get<User[]>('/users')
        .then((response) => response.data)
        .catch((error) => {
          message.error(error.message, messageDuration.medium);
          return Promise.reject(error);
        }),
    {
      staleTime: Infinity,
      retry: false,
      ...(options && { ...options })
    }
  );
};

//
// useUserQuery
export const useUserQuery = (params: { userId: number }, options?: QueryObserverOptions<User>) => {
  const message = useMessage();

  return useQuery<User>(
    [QueryKey.User, params],
    () =>
      backendApi
        .get<User>(`/users/${params.userId}`)
        .then((response) => response.data)
        .catch((error) => {
          message.error(error.message, messageDuration.medium);
          return Promise.reject(error);
        }),
    { retry: false, staleTime: Infinity, ...(options && { ...options }) }
  );
};

//
// useUsersOverviewQuery
export interface UsersOverviewQueryParams {
  filters?: {
    [FilterMetadataApiKeys.UserLoginStateKey]?: ActiveInactive;
    [FilterMetadataApiKeys.UserIdKey]?: number[];
  };
  pagination?: QueryPagination;
}
export const useUsersOverviewQuery = (
  params: UsersOverviewQueryParams,
  options?: QueryObserverOptions<{ data: UserOverview[] }>
) => {
  const message = useMessage();

  return useQuery<{ data: UserOverview[] }>(
    [QueryKey.UsersOverview, params],
    () =>
      backendApi
        .get<{ data: UserOverview[] }>('/data/users-overview', {
          params: {
            ...(params.filters?.[FilterMetadataApiKeys.UserLoginStateKey] && {
              loginState: params.filters[FilterMetadataApiKeys.UserLoginStateKey]
            }),
            ...(params.filters?.[FilterMetadataApiKeys.UserIdKey] && {
              userId: params.filters[FilterMetadataApiKeys.UserIdKey]
            }),
            ...(params.pagination && { range: convertPaginationToGetRange(params.pagination) })
          }
        })
        .then((response) => response.data)
        .catch((error) => {
          message.error(error.message, messageDuration.medium);
          return Promise.reject(error);
        }),
    {
      retry: false,
      ...(options && { ...options })
    }
  );
};

//
// useUserSyncStatusQuery
type UserSyncStatus = 'Active' | 'Inactive';
export const useUserSyncStatusQuery = (userId: number, options?: QueryObserverOptions<{ status: UserSyncStatus }>) => {
  const message = useMessage();

  return useQuery<{ status: UserSyncStatus }>(
    [QueryKey.UsersSyncStatus, userId],
    () =>
      backendApi
        .post<{ status: UserSyncStatus }>(`/users/${userId}/sync-status`)
        .then((response) => response.data)
        .catch((error) => {
          message.error(error.message, messageDuration.medium);
          return Promise.reject(error);
        }),
    {
      retry: false,
      ...(options && { ...options })
    }
  );
};

//
// useUserStatisticsQuery
interface UserStatisticsParams {
  userId: number;
  dateRange: DateRangeValue;
}

export const useUserStatisticsQuery = (
  params: UserStatisticsParams,
  options?: QueryObserverOptions<UserStatistics>
) => {
  const message = useMessage();

  return useQuery<UserStatistics>(
    [QueryKey.UserStatistics, params],
    () =>
      backendApi
        .get<UserStatistics>(`/users/${params.userId}/statistics`, {
          params: { dateRange: convertDateRangeValueToQueryDateRange(params.dateRange) }
        })
        .then((response) => response.data)
        .catch((error) => {
          message.error(error.message, messageDuration.medium);
          return Promise.reject(error);
        }),
    { retry: false, ...(options && { ...options }) }
  );
};

//
// useUserWeeklyDigestQuery
export const useUserWeeklyDigestQuery = (
  params: { userId: number; fallbackToOrganization: boolean },
  options?: QueryObserverOptions<UserWeeklyDigestData | null>
) => {
  const message = useMessage();

  return useQuery<UserWeeklyDigestData | null>(
    [QueryKey.WeeklyDigest, params],
    () =>
      backendApi
        .get<UserWeeklyDigestData | null>(
          params.fallbackToOrganization ? `/users/digest` : `/users/${params.userId}/digest`
        )
        .then((response) => response.data)
        .catch((error) => {
          message.error(error.message, messageDuration.medium);
          return Promise.reject(error);
        }),
    {
      ...{ retry: false, staleTime: Infinity },
      ...(options && { ...options })
    }
  );
};

//
// useUsersEssentialsQuery
export const useUsersEssentialsQuery = (
  options?: QueryObserverOptions<{ data: CustomReportData[] }>
): UseQueryResult<{ data: UserEssentials[] }> =>
  useCustomReportDataQuery(
    {
      entity: ReportRefEntityType.User,
      columns: [
        { key: 'user', field: { id: 'user.id' } },
        { key: 'manager', field: { id: 'user.manager' } }
      ],
      filter: { operator: 'AND', conditions: [] },
      arguments: {}
    },
    { ...options, staleTime: Infinity }
  ) as UseQueryResult<{ data: UserEssentials[] }>;
