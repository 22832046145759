import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { isJsonString } from 'Utils/functional';
import { decodeParamsFromBase64 } from 'Utils/url';

enum GainsightEntry {
  Dashboard = 'Dashboard',
  Customer = 'Customer',
  User = 'User',
  Stakeholder = 'Stakeholder',
  Reports = 'Reports',
  Lookup = 'Lookup',
  PersonalSettings = 'PersonalSettings',
  OnboardingChecklist = 'OnboardingChecklist',
  SystemStats = 'SystemStats',
  Settings = 'Settings'
}

const SECTION_NAME_TO_ENTRY: Record<string, GainsightEntry> = {
  dashboard: GainsightEntry.Dashboard,
  customer: GainsightEntry.Customer,
  person: GainsightEntry.Stakeholder,
  reports: GainsightEntry.Reports,
  lookup: GainsightEntry.Lookup,
  personal_settings: GainsightEntry.PersonalSettings,
  onboarding_checklist: GainsightEntry.OnboardingChecklist,
  system_stats: GainsightEntry.SystemStats,
  settings: GainsightEntry.Settings
};

const ENTRY_TO_PATH: Record<GainsightEntry, string> = {
  [GainsightEntry.Dashboard]: '/dashboard',
  [GainsightEntry.Customer]: '/gs-customer',
  [GainsightEntry.User]: '/users',
  [GainsightEntry.Stakeholder]: '/gs-stakeholder',
  [GainsightEntry.Reports]: '/reports',
  [GainsightEntry.Lookup]: '/lookup',
  [GainsightEntry.PersonalSettings]: '/personal-settings',
  [GainsightEntry.OnboardingChecklist]: '/onboarding-checklist',
  [GainsightEntry.SystemStats]: '/system/stats',
  [GainsightEntry.Settings]: '/company-settings'
};

interface GainsightState {
  entry: GainsightEntry | undefined;
}

const GAINSIGHT_COMPANY_ID_PARAM = 'gs-company-id';
const GAINSIGHT_STAKEHOLDER_ID_PARAM = 'gs-company_person-id';
const GainsightContext = React.createContext<GainsightState>({ entry: undefined });

const GainsightProvider: React.FC<{ children?: ReactNode }> = (props) => {
  const [entry, setEntry] = useState<GainsightEntry>();
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const getAdditionalParams = useCallback(
    (gainsightEntry: GainsightEntry): string | null => {
      switch (gainsightEntry) {
        case GainsightEntry.Customer:
          return searchParams.get(GAINSIGHT_COMPANY_ID_PARAM);
        case GainsightEntry.Stakeholder:
          return searchParams.get(GAINSIGHT_STAKEHOLDER_ID_PARAM);
        default:
          return null;
      }
    },
    [searchParams]
  );

  const getScRoute = useCallback((scRoute: string | null): string | null => {
    if (
      scRoute &&
      (typeof decodeParamsFromBase64(scRoute) === 'object' || isJsonString(decodeParamsFromBase64(scRoute)))
    ) {
      const route: string | undefined = (decodeParamsFromBase64(scRoute) as { route: string })?.route;
      return route || null;
    } else {
      return null;
    }
  }, []);

  const updateURL = useCallback(
    (gainsightEntry: GainsightEntry, scRoute: string | null) => {
      const scRouteStr = getScRoute(scRoute);
      if (scRouteStr) {
        navigate(scRouteStr, { replace: true });
      } else {
        const additionalParams = getAdditionalParams(gainsightEntry);
        const path = `${ENTRY_TO_PATH[gainsightEntry]}${additionalParams ? `/${additionalParams}` : ''}`;
        navigate(path, { replace: true });
      }
    },
    [navigate, getAdditionalParams, getScRoute]
  );

  useEffect(() => {
    const section = searchParams.get('section');
    const scRoute = searchParams.get('sc-route');
    const gainsightEntry = section ? SECTION_NAME_TO_ENTRY[section] : undefined;
    gainsightEntry && updateURL(gainsightEntry, scRoute);
    setEntry(gainsightEntry);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <GainsightContext.Provider value={{ entry }} {...props} />;
};

const useGainsightContext = (): GainsightState => React.useContext(GainsightContext);

export { GainsightEntry, GainsightProvider, useGainsightContext };
