import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, messageDuration, useMessage } from 'Components/Primitives';
import { useUpdateUserMutation } from 'hooks';
import styled from '@emotion/styled';
import { ErrorResponse } from 'http/Request';
import { User } from 'Types/User.types';
import { UserForm, USER_FORM_ID } from 'Components/Users/UserForm/UserForm';
import { UserFormData } from 'Components/Users/UserForm/UserForm.types';

export interface EditUserInPageProps {
  user: User;
}

const StyledEditUserInPage = styled.div`
  display: flex;
  flex-direction: column;
  .edit-user-submit {
    align-self: flex-end;
  }
`;

export const EditUserInPage: React.FC<EditUserInPageProps> = ({ user }) => {
  const { t } = useTranslation(['usersOverview', 'common']);
  const message = useMessage();

  const { mutate, isLoading } = useUpdateUserMutation({
    onSuccess: (userRes: User) => {
      message.success(t('update-user.update-success', { userName: userRes.fullName }), messageDuration.medium);
    },
    onError: (error: ErrorResponse) => message.error(error.message)
  });

  const onSubmit = useCallback(
    (e: UserFormData) => {
      user && mutate({ id: user.id, data: e });
    },
    [user, mutate]
  );

  return (
    <StyledEditUserInPage>
      <UserForm onSubmit={onSubmit} user={user} />
      <Button
        className="edit-user-submit"
        data-testid="edit-user-submit"
        type="primary"
        htmlType="submit"
        form={USER_FORM_ID}
        loading={isLoading}
      >
        {t('common:update')}
      </Button>
    </StyledEditUserInPage>
  );
};
