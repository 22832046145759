import React, { ReactElement, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Select as RefSelect, SelectProps as BaseSelectProps } from 'antd';
import type { CustomTagProps, RenderDOMFunc } from 'rc-select/lib/BaseSelect';
import { nonNullable } from 'Utils/functional';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Colors, times8 } from 'Constants/Styles';
import { Icon } from 'Components/Primitives/Icon/Icon';
import { SelectChangeValue, SelectOption } from 'Components/Primitives/Select/Select.types';
import { Icons } from 'Components/Primitives/Icon/Icon.types';
import { LabelValueType } from 'Components/Primitives/Tree/Tree.types';
import { Checkbox, CheckboxChangeEvent } from 'Components/Primitives/Checkbox/Checkbox';
import { Highlight } from 'Components/Primitives/Highlight/Highlight';

export interface SelectProps {
  options?: SelectOption[];
  value?: SelectChangeValue;
  loading?: boolean;
  placeholder?: string;
  emptyAsAll?: boolean;
  showSearch?: boolean;
  mode?: 'single' | 'multiple' | 'tags';
  className?: string;
  allowClear?: boolean;
  optionFilterProp?: string;
  maxTagCount?: number | 'responsive';
  borderless?: boolean;
  disabled?: boolean;
  placement?: BaseSelectProps['placement'];
  popupMatchSelectWidth?: boolean;
  openByDefault?: boolean;
  'data-testid'?: string;
  tagRender?(props: CustomTagProps): ReactElement;
  maxTagPlaceholder?(additionalValues: LabelValueType[]): ReactNode;
  getPopupContainer?: RenderDOMFunc | undefined;
  onChange?(value: SelectChangeValue, option: SelectOption | SelectOption[] | null | undefined): void;
}

type SelectOptionValue = number | string | (string | number)[] | undefined;
interface OnChangeParams {
  val: SelectOptionValue;
  option: SelectOption | SelectOption[] | undefined;
}

const StyledSelectAll = styled.div`
  padding: ${times8()}px;
  margin-bottom: ${times8()}px;
  border-bottom: 1px solid ${Colors.Border};
`;

const DEFAULT_MAX_TAG_COUNT = 12;
const NUM_OPTIONS_FOR_SELECT_ALL_CB = 4;
type SelectAllState = 'indeterminate' | 'checked' | undefined;

const defaultMaxTagPlaceholder = (additionalValues: LabelValueType[]): ReactNode => <>+{additionalValues.length} ...</>;

export const Select: React.FC<SelectProps> = ({
  options,
  value: defaultValue,
  showSearch = true,
  mode,
  allowClear,
  placeholder,
  optionFilterProp,
  emptyAsAll,
  className,
  maxTagCount,
  disabled = false,
  loading,
  borderless = false,
  placement,
  popupMatchSelectWidth = true,
  openByDefault = false,
  'data-testid': dataTestId,
  tagRender,
  maxTagPlaceholder,
  getPopupContainer,
  onChange
}) => {
  const { t } = useTranslation(['common']);
  const [isOpen, setIsOpen] = useState<boolean>(openByDefault);
  const [selected, setSelected] = useState<OnChangeParams>();

  const [sanitized, setSanitzed] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>();
  const [selectOptions, setSelectOptions] = useState<SelectOption[]>();

  const selectMultiple = useMemo((): boolean => mode === 'multiple' || mode === 'tags', [mode]);
  const showSelectAll = useMemo(
    () => selectMultiple && options && options.length > NUM_OPTIONS_FOR_SELECT_ALL_CB,
    [selectMultiple, options]
  );
  const showAllowClear = useMemo(
    (): boolean =>
      (allowClear || false) &&
      (Array.isArray(selected?.val) ? (selected?.val || []).length > 0 : selected?.val !== undefined),
    [allowClear, selected]
  );
  const indicateAllSelected = useMemo((): boolean => {
    if (!selectMultiple || !emptyAsAll) return false;
    return (
      selected?.val === undefined || (Array.isArray(selected?.val) && (selected?.val || []).length === options?.length)
    );
  }, [selected, selectMultiple, emptyAsAll, options]);

  const suffixIcon = useMemo((): ReactNode => {
    return isOpen ? (
      showSearch ? (
        <Icon icon={Icons.Search} />
      ) : (
        <Icon icon={Icons.ChevronUp} />
      )
    ) : (
      <Icon icon={Icons.ChevronDown} />
    );
  }, [isOpen, showSearch]);

  const selectAllCheckState = useMemo((): SelectAllState => {
    if (!Array.isArray(selected?.val)) return undefined;
    if ((selected?.val || []).length > 0 && (selected?.val || []).length < (options || []).length) {
      return 'indeterminate';
    } else if ((selected?.val || []).length === (options || []).length) {
      return 'checked';
    } else {
      return undefined;
    }
  }, [selected, options]);

  const dropdownVisibleChange = useCallback(
    (e: boolean) => {
      setIsOpen(e);
      if (e === false && selected) {
        const changeValue: OnChangeParams = indicateAllSelected
          ? emptyAsAll
            ? { val: undefined, option: undefined }
            : selected
          : selected || { val: undefined, option: undefined };
        setSelected(changeValue);
        selectMultiple && onChange && onChange(changeValue.val || null, changeValue.option || null);
        setSearchTerm(undefined);
      }
    },
    [selected, emptyAsAll, selectMultiple, indicateAllSelected, onChange]
  );

  const onSelectChange = useCallback(
    (v: SelectOptionValue, option: SelectOption | SelectOption[] | undefined) => {
      const val: OnChangeParams = Array.isArray(option)
        ? option.length === 0
          ? { val: undefined, option: [] }
          : { val: v, option }
        : { val: v, option: option };
      setSelected(val);
      (selectMultiple === false || isOpen === false) && onChange && onChange(val.val || null, option);
      setSearchTerm(undefined);
    },
    [selectMultiple, isOpen, onChange]
  );

  const onSelectAllChange = useCallback(
    (e: CheckboxChangeEvent) => {
      if (e.target.checked) {
        const values = options?.map((option) => option.value);
        if (values) {
          setSelected({ val: values, option: options });
        }
      } else {
        setSelected({ val: undefined, option: undefined });
      }
    },
    [options]
  );

  const onSearch = useCallback((q: string) => {
    setSearchTerm(q);
  }, []);

  useEffect(() => {
    if (!options) return;
    if (selectMultiple) {
      setSelected(
        defaultValue && Array.isArray(defaultValue)
          ? { val: defaultValue, option: options.filter((o) => defaultValue.includes(o.value)) }
          : { val: undefined, option: undefined }
      );
    } else {
      setSelected(
        defaultValue ? { val: defaultValue, option: options.find((o) => o.value === defaultValue) } : undefined
      );
    }
  }, [selectMultiple, options, defaultValue, emptyAsAll]);

  useEffect(() => {
    if (!options) return;
    setSelectOptions(
      options.map((option) => {
        const label =
          typeof option.label === 'string' && searchTerm ? (
            <Highlight text={option.label} wholeWordOnly={false} highlight={[searchTerm]} />
          ) : (
            option.label
          );
        const searchValue: string | undefined = option.searchValue
          ? option.searchValue
          : typeof option.label === 'string'
            ? option.label
            : undefined;

        return {
          ...option,
          label,
          ...(searchValue && { searchValue })
        };
      })
    );
  }, [options, searchTerm]);

  //
  // sanitizing the select values, removing values that are not part of options
  //
  useEffect(() => {
    if (defaultValue && options && options.length > 0 && !sanitized) {
      const optionsValues = options.map((o) => o.value);
      if (Array.isArray(defaultValue)) {
        const validValues: (string | number)[] = defaultValue
          .filter(nonNullable)
          .filter((v) => v && optionsValues.includes(v));

        if (validValues.length < defaultValue.length) {
          const validOptions = options.filter((o) => validValues.includes(o.value));
          setSelected({ val: validValues, option: validOptions });
          onChange && validValues.length === 0 && onChange(null, null);
          onChange && validValues.length > 0 && onChange(validValues, validOptions);
        }
      } else {
        const valueIsValid: boolean = optionsValues.includes(defaultValue);
        if (!valueIsValid) {
          setSelected({ val: undefined, option: undefined });
          onChange && onChange(null, null);
        }
      }
      setSanitzed(true);
    }
  }, [defaultValue, options, sanitized, onChange]);

  return (
    <RefSelect
      value={selected?.val}
      options={selectOptions}
      className={classNames(className, indicateAllSelected && 'indicate-all')}
      showSearch={showSearch}
      data-testid={dataTestId}
      placeholder={placeholder}
      mode={mode && mode !== 'single' ? mode : undefined}
      open={isOpen}
      allowClear={showAllowClear}
      onDropdownVisibleChange={dropdownVisibleChange}
      suffixIcon={loading ? undefined : suffixIcon}
      optionFilterProp={optionFilterProp || 'searchValue'}
      maxTagPlaceholder={maxTagPlaceholder || defaultMaxTagPlaceholder}
      maxTagCount={maxTagCount || DEFAULT_MAX_TAG_COUNT}
      disabled={disabled}
      loading={loading}
      variant={borderless ? 'borderless' : undefined}
      placement={placement}
      dropdownStyle={{ minWidth: 250 }}
      popupMatchSelectWidth={popupMatchSelectWidth}
      onChange={onSelectChange}
      tagRender={tagRender}
      onSearch={showSearch ? onSearch : undefined}
      getPopupContainer={getPopupContainer}
      dropdownRender={(originalNode: ReactNode) => (
        <>
          {showSelectAll && (
            <StyledSelectAll>
              <Checkbox
                data-testid="select-all-checkbox"
                onChange={onSelectAllChange}
                indeterminate={selectAllCheckState === 'indeterminate'}
                checked={selectAllCheckState === 'checked'}
              >
                {t('all')}
              </Checkbox>
            </StyledSelectAll>
          )}
          {originalNode}
        </>
      )}
    />
  );
};
