import { createContext, ReactNode, useCallback, useContext, useMemo } from 'react';
import { message as RefMessage } from 'antd';
import { simpleUID } from 'Utils/functional';

export const messageDuration = {
  short: 3,
  medium: 6,
  long: 10
};

const errorMessageMap: Map<string, string> = new Map();

interface MessageContextProps {
  success(content: ReactNode, duration?: number): void;
  error(content: ReactNode, duration?: number): void;
}
const emptyContext: MessageContextProps = { success: () => {}, error: () => {} };
const MessageContext = createContext<MessageContextProps>(emptyContext);
export const MessageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [messageApi, contextHolder] = RefMessage.useMessage();

  const success = useCallback(
    (content: ReactNode, duration?: number) => {
      messageApi.open({
        type: 'success',
        content,
        duration
      });
    },
    [messageApi]
  );

  const error = useCallback(
    (content: string | undefined, duration?: number) => {
      if (!content) return;
      if (Array.from(errorMessageMap.values()).includes(content)) return;

      const key = simpleUID('message-key-', 8);
      errorMessageMap.set(key, content);

      messageApi.open({
        type: 'error',
        content,
        key,
        duration,
        onClose: () => {
          errorMessageMap.delete(key);
        }
      });
    },
    [messageApi]
  );

  const value = useMemo(() => ({ success, error }), [success, error]);

  return (
    <MessageContext.Provider value={value}>
      {contextHolder}
      {children}
    </MessageContext.Provider>
  );
};

export const useMessage = () => {
  const value = useContext(MessageContext);
  return value;
};
