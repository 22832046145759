import React, { ReactNode } from 'react';
import ConfigProvider from 'antd/es/config-provider';
import { ColorPalette, Colors } from 'Constants/Styles';
import { getColorWithBrightness } from 'Utils/colors';
import { MessageProvider } from 'Components/Primitives';

//
// https://ant.design/docs/react/customize-theme
// https://ant.design/theme-editor-cn
const AntdConfigProvider: React.FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: Colors.Primary,
          colorTextBase: Colors.Text,
          colorLink: Colors.Primary,
          colorLinkHover: getColorWithBrightness(Colors.Primary, 0.1),

          fontFamily: 'Noto Sans',
          fontSize: 14,
          fontSizeLG: 14,
          lineHeight: 1.5,

          motionDurationSlow: '0.1s',
          motionDurationMid: '0.1s',
          motionDurationFast: '0.05s',

          controlHeight: 40,
          controlHeightLG: 40,
          controlHeightSM: 32,
          controlHeightXS: 32,

          borderRadius: 6,
          borderRadiusSM: 4,

          colorBorder: Colors.Border,

          colorTextLabel: Colors.Text,
          colorTextPlaceholder: Colors.SubText
        },
        components: {
          Button: {
            borderRadius: 20,
            borderRadiusLG: 20,
            borderRadiusSM: 16,
            paddingInlineSM: 14
          },
          Divider: {
            colorSplit: Colors.Border
          },
          Tooltip: {
            colorBgBase: '#222426',
            borderRadiusXS: 2,
            sizePopupArrow: 12
          },
          Wave: {
            algorithm: undefined
          },
          Popover: {
            sizePopupArrow: 10
          },
          Form: {
            colorBorder: ColorPalette.plainGray20
          },
          Drawer: {
            motionDurationSlow: '0.3s'
          },
          Checkbox: {
            colorBorder: ColorPalette.plainGray40,
            borderRadiusSM: 2
          },
          Tree: {
            nodeSelectedBg: 'transparent'
          },
          TreeSelect: {
            borderRadiusSM: 2
          },
          Spin: {
            controlHeight: 32,
            controlHeightLG: 40
          },
          Collapse: {
            colorFillAlter: ColorPalette.plainGray0,
            headerPadding: '16px 16px'
          },
          Slider: {
            railSize: 6,
            handleSize: 12,
            handleSizeHover: 12,
            handleLineWidth: 2,
            handleLineWidthHover: 2
          },
          Table: {
            colorFillAlter: '#b6b6b60d'
          }
        }
      }}
      wave={{ disabled: true }}
    >
      <MessageProvider>{children}</MessageProvider>
    </ConfigProvider>
  );
};

export { AntdConfigProvider };
