import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActiveInactive, ListMap, ListMetadata, ListType } from 'Types/Shared.types';
import { useQuery } from 'react-query';
import { QueryKey } from 'Constants/QueryKeys.enum';
import { backendApi } from 'http/Request';
import { messageDuration, useMessage } from 'Components/Primitives';

export type ListsMetadata = {
  enums: Partial<Record<ListType, ListMetadata>>;
  lists: Partial<Record<ListType, ListMetadata>>;
};

const listMapperDefaults: ListMap = {};

export const useListsQuery = (
  enabled = true
): {
  lists: ListMap;
  isLoading: boolean;
} => {
  const { t } = useTranslation(['common']);
  const [lists, setLists] = useState<ListMap>(listMapperDefaults);
  const [loading, setLoading] = useState<boolean>(true);
  const message = useMessage();

  const { data } = useQuery<ListsMetadata>(
    [QueryKey.Lists],
    () =>
      backendApi
        .get<ListsMetadata>('/metadata/lists')
        .then((response) => response.data)
        .catch((error) => {
          message.error(error.message, messageDuration.medium);
          return Promise.reject(error);
        }),
    { retry: false, staleTime: Infinity, enabled }
  );

  const staticLists = useMemo(
    (): ListMap => ({
      [ListType.ActiveInactive]: [
        { name: t('customer-status.active'), id: ActiveInactive.Active, position: 0 },
        { name: t('customer-status.inactive'), id: ActiveInactive.Inactive, position: 1 }
      ],
      [ListType.Boolean]: [
        { name: t('boolean-select.true'), id: 'true', position: 0 },
        { name: t('boolean-select.false'), id: 'false', position: 1 }
      ],
      [ListType.UserRoles]: [
        { name: t('user-roles-select.admin'), id: 'admin', position: 0 },
        { name: t('user-roles-select.manager'), id: 'manager', position: 1 },
        { name: t('user-roles-select.user'), id: 'user', position: 2 }
      ]
    }),
    [t]
  );

  useEffect(() => {
    if (!data) return;
    const enumMap: ListMap = (Object.keys(data.enums) as ListType[]).reduce(
      (acc: ListMap, lmdKey: ListType) => ({ ...acc, [lmdKey]: data.enums[lmdKey]?.values || [] }),
      {}
    );
    const listsMap: ListMap = (Object.keys(data.lists) as ListType[]).reduce(
      (acc: ListMap, lmdKey: ListType) => ({ ...acc, [lmdKey]: data.lists[lmdKey]?.values || [] }),
      {}
    );
    setLists({ ...staticLists, ...enumMap, ...listsMap });
    setLoading(false);
  }, [staticLists, data]);

  const response = useMemo(
    (): {
      lists: ListMap;
      isLoading: boolean;
    } => ({ lists, isLoading: loading }),
    [lists, loading]
  );

  return response;
};
