import React, { CSSProperties, useMemo } from 'react';
import { type Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/dist/types/closest-edge';

const EDGE_CLASS_MAP: { [key: string]: string } = {
  top: 'edge-top',
  bottom: 'edge-bottom'
};

const DropIndicator: React.FC<{ edge: Edge; gap: number }> = ({ edge, gap }) => {
  const edgeClass = useMemo((): string => EDGE_CLASS_MAP[edge], [edge]);

  const style = useMemo(
    (): CSSProperties => ({
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ['--gap' as any]: `${gap}px`
    }),
    [gap]
  );

  return <div className={`drop-indicator ${edgeClass}`} style={style}></div>;
};

export default DropIndicator;
