import { QueryObserverOptions, useQuery } from 'react-query';
import { backendApi } from 'http/Request';
import { messageDuration, useMessage } from 'Components/Primitives';
import { QueryKey } from 'Constants/QueryKeys.enum';

export const useFeatureFlagDataQuery = (options?: QueryObserverOptions<{ data: string[] }>) => {
  const message = useMessage();

  return useQuery<{ data: string[] }>(
    [QueryKey.FeatureFlags],
    () =>
      backendApi
        .get<{ data: string[] }>('/toggle')
        .then((response) => response.data)
        .catch((error) => {
          message.error(error.message, messageDuration.medium);
          return Promise.reject(error);
        }),
    {
      ...{ retry: false, staleTime: Infinity },
      ...(options && { ...options })
    }
  );
};
