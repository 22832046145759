import React, { useMemo } from 'react';
import { FormFieldMetadata, FormFieldType, FormGenerator } from 'Components/FormGenerator';
import { User } from 'Types/User.types';
import { Form } from 'Components/Primitives';
import { UserFormData } from 'Components/Users/UserForm/UserForm.types';
import { useTranslation } from 'react-i18next';
import { useEntityRefSelectValues } from 'Components/Fields';
import { ReportRefEntityType } from 'Types/ReportField.types';

interface UserFormProps {
  user?: User;
  onSubmit(e: UserFormData): void;
}

export const USER_FORM_ID = 'userFormId';

export const UserForm: React.FC<UserFormProps> = ({ user, onSubmit }) => {
  const { t } = useTranslation(['usersOverview']);
  const [form] = Form.useForm<UserFormData>();
  const { getEntityRefValues } = useEntityRefSelectValues();

  const userFormMetadata = useMemo(
    (): FormFieldMetadata[] => [
      {
        id: 'firstName',
        label: t('user-form.first-name-label'),
        fieldType: FormFieldType.String,
        required: true
      },
      {
        id: 'lastName',
        label: t('user-form.last-name-label'),
        fieldType: FormFieldType.String,
        required: true
      },
      {
        id: 'email',
        label: t('user-form.email-label'),
        fieldType: FormFieldType.String,
        disabled: user ? true : false,
        required: user ? false : true
      },
      {
        id: 'title',
        label: t('user-form.title-label'),
        fieldType: FormFieldType.String
      },
      {
        id: 'department',
        label: t('user-form.department-label'),
        fieldType: FormFieldType.String
      },
      {
        id: 'managerId',
        label: t('user-form.manager-label'),
        fieldType: FormFieldType.TreeList,
        treeOptions: getEntityRefValues(ReportRefEntityType.User),
        listOptions: {
          mode: 'single'
        }
      },
      {
        id: 'whenPresentIgnore',
        placeholder: t('user-form.exclude-from-processing-label'),
        help: t('user-form.exclude-from-processing-placeholder'),
        fieldType: FormFieldType.Boolean,
        booleanRendering: 'checkbox',
        colSize: 'col-12'
      }
    ],
    [t, user, getEntityRefValues]
  );

  return (
    <FormGenerator
      metadata={userFormMetadata}
      form={form}
      id={USER_FORM_ID}
      layout="horizontal"
      data={user}
      onFinish={onSubmit}
    />
  );
};
