import React, { MouseEvent, useCallback } from 'react';
import { css } from '@emotion/react';
import { Button, Icon, Icons, Tooltip } from 'Components/Primitives';
import html2canvas from 'html2canvas';
import { times8 } from 'Constants/Styles';
import { simpleUID } from 'Utils/functional';
import classNames from 'classnames';
import { useTrack } from 'Utils/analytics';
import { TrackEventName } from 'Constants/AnalyticsEvent.enum';

interface DownloadDomAsImageButtonProps {
  element: HTMLElement;
  trackName: string;
  buttonRef?: React.RefObject<HTMLButtonElement | null>;
  fileName?: string;
  className?: string;
}

export const DownloadDomAsImageButtonStyle = css`
  position: relative;
  .download-widget-as-image-button {
    position: absolute;
    z-index: 10;
    bottom: ${times8(2)}px;
    right: ${times8(2)}px;
    opacity: 0;
    transform-origin: center bottom;
    transform: translateY(10px) scaleY(0.5);
  }
  &:hover {
    .download-widget-as-image-button {
      opacity: 1;
      transform: translateY(0) scaleY(1);
    }
  }
`;

export const DownloadDomAsImageButton: React.FC<DownloadDomAsImageButtonProps> = ({
  element,
  trackName,
  buttonRef,
  fileName,
  className
}) => {
  const { track } = useTrack();

  const onClick = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();
      track({ eventName: TrackEventName.DownloadImage, params: { widget: trackName } });

      const dnlFileName = fileName || simpleUID('staircase-ai', 8);
      html2canvas(element).then((canvas) => {
        const link = document.createElement('a');
        const dataURL = canvas.toDataURL('image/png');
        link.download = `${dnlFileName}.png`;
        link.href = dataURL;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    [element, fileName, trackName, track]
  );

  return (
    <Tooltip title="Download image">
      <Button
        size="small"
        className={classNames('download-widget-as-image-button', className)}
        onClick={onClick}
        icon={<Icon icon={Icons.Download} />}
        ref={buttonRef}
      />
    </Tooltip>
  );
};
