import { messageDuration, useMessage } from 'Components/Primitives';
import { QueryKey } from 'Constants/QueryKeys.enum';
import { backendApi } from 'http/Request';
import { QueryObserverOptions, useQuery, UseQueryResult } from 'react-query';
import { CustomReportColumn, CustomReportData } from 'Types/Reports.types';
import { Stakeholder, StakeholderEssentials } from 'Types/Stakeholder.types';
import { useCustomReportDataQuery } from 'hooks/QueryHooks/useCustomReportQuery';
import { ReportRefEntityType, ReportFilterOperator } from 'Types/ReportField.types';

//
// useStakeholdersEssentialsQuery
//
const STAKEHOLDER_ESSENTIALS_COLUMNS: CustomReportColumn[] = [{ key: 'stakeholder', field: { id: 'stakeholder.id' } }];
export const useStakeholdersEssentialsQuery = (
  params: { customerId: number },
  options?: QueryObserverOptions<{ data: CustomReportData[] }>
) => {
  return useCustomReportDataQuery(
    {
      entity: ReportRefEntityType.Stakeholder,
      columns: STAKEHOLDER_ESSENTIALS_COLUMNS,
      filter: {
        operator: 'AND',
        conditions: [
          {
            field: { id: 'stakeholder.customer' },
            operator: ReportFilterOperator.Equal,
            value: params.customerId,
            values: []
          }
        ]
      },
      arguments: {}
    },
    { ...options, staleTime: Infinity }
  ) as UseQueryResult<{ data: StakeholderEssentials[] }>;
};

//
// useStakeholdersQuery
//
interface StakeholdersQueryFilters {
  customerId: number[];
}

export const useStakeholdersQuery = (filters: StakeholdersQueryFilters) => {
  const message = useMessage();

  return useQuery<{ data: Stakeholder[] }>(
    [QueryKey.Stakeholders, filters],
    () =>
      backendApi
        .get<{ data: Stakeholder[] }>('/stakeholders', {
          params: { customerId: filters.customerId }
        })
        .then((response) => response.data)
        .catch((error) => {
          message.error(error.message, messageDuration.medium);
          return Promise.reject(error);
        }),
    {
      retry: false
    }
  );
};
