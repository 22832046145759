import { UserEssentials } from 'Types/User.types';
import { UsersMap } from 'hooks';
import { normalizedStringValue } from 'Utils/text';
import { translate } from 'Utils/translate';
import { AvatarAndTitle, Highlight, TreeSelectValue, TreeNode } from 'Components/Primitives';
import { nonNullable, uniqFast } from 'Utils/functional';

interface UsersChildrenMap {
  [key: string]: number[];
}

const getUsersTree = (
  users: UserEssentials[],
  managerId: number | null,
  managerChildrenMap: UsersChildrenMap,
  searchTerm: string | undefined
): TreeNode[] => {
  const children = users.filter((user) => user.manager?.value === managerId);
  return children
    .sort((a, b) => {
      const childrenA = managerChildrenMap[a.user.value.toString()]?.length || 0;
      const childrenB = managerChildrenMap[b.user.value.toString()]?.length || 0;
      return childrenB - childrenA;
    })
    .map((child) => {
      const hasChildren = managerChildrenMap[child.user.value.toString()]?.length > 0;
      const teamName = translate('common:team', { name: child.user.data.fullName || '' });
      return {
        title: hasChildren ? (
          searchTerm ? (
            <Highlight text={teamName} wholeWordOnly={false} highlight={[searchTerm]} />
          ) : (
            teamName
          )
        ) : (
          <AvatarAndTitle
            size="small"
            title={normalizedStringValue(child.user.data.fullName || '')}
            avatarSrc={child.user.data.avatar}
            highlight={searchTerm ? [searchTerm] : undefined}
          />
        ),
        searchValue: hasChildren ? teamName : normalizedStringValue(child.user.data.fullName || ''),
        value: hasChildren ? teamName : child.user.value,
        selectable: !hasChildren,
        children: hasChildren
          ? [
              {
                title: (
                  <AvatarAndTitle
                    size="small"
                    title={normalizedStringValue(child.user.data.fullName || '')}
                    avatarSrc={child.user.data.avatar}
                    highlight={searchTerm ? [searchTerm] : undefined}
                  />
                ),
                searchValue: normalizedStringValue(child.user.data.fullName || ''),
                value: child.user.value,
                selectable: true,
                children: []
              },
              ...getUsersTree(users, child.user.value, managerChildrenMap, searchTerm)
            ]
          : []
      };
    });
};

export const getManagerChildrenMap = (users: UserEssentials[]): UsersChildrenMap => {
  const usersChildrenMap = users.reduce((acc: UsersChildrenMap, user) => {
    const managerId: number | null = user.manager?.value && user.manager?.data.fullName ? user.manager?.value : null;
    return managerId ? { ...acc, [managerId]: [...(acc[managerId] || []), user.user.value] } : acc;
  }, {});

  return usersChildrenMap;
};

const getUsersList = (users: UserEssentials[], userSelectType: 'all' | 'managers'): UserEssentials[] => {
  const usersListWithoutSelfManagers = users.map((user) =>
    user.user.value !== user.manager?.value
      ? user
      : { ...user, manager: { value: null, data: { fullName: null, avatar: null } } }
  );
  if (userSelectType === 'all') {
    return usersListWithoutSelfManagers;
  }
  const managersIds = uniqFast(usersListWithoutSelfManagers.map((user) => user.manager?.value).filter(nonNullable));
  return usersListWithoutSelfManagers.filter((user) => managersIds.includes(user.user.value));
};

export const getUsersTreeSelectOptions = (
  users: UserEssentials[],
  searchTerm: string | undefined,
  userSelectType: 'all' | 'managers'
): TreeNode[] => {
  const usersList = getUsersList(users, userSelectType);
  const managerChildrenMap = getManagerChildrenMap(usersList);
  const usersTree = getUsersTree(usersList, null, managerChildrenMap, searchTerm);
  return usersTree;
};

export const getSelectedUsers = (
  selectedIds: TreeSelectValue,
  usersMap: UsersMap
): UserEssentials | UserEssentials[] | null => {
  return selectedIds
    ? typeof selectedIds === 'number'
      ? usersMap.get(selectedIds) || null
      : (selectedIds as number[]).map((id) => usersMap.get(id)).filter(nonNullable)
    : null;
};
