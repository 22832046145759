import { User } from 'Types/User.types';
import { Sentiments } from 'Types/Sentiment.types';
import { Stakeholder } from 'Types/Stakeholder.types';
import { ValueAndTrend } from 'Types/Shared.types';
import { ReportCustomerRefData } from 'Types/Reports.types';

export enum CustomerStatus {
  Active = 'Active',
  Churned = 'Churned',
  Prospect = 'Prospect'
}

export interface CustomerSuggestion {
  id: number;
  added: boolean;
  domains: string[];
  name: string;
}

export const BASE_CUSTOMER_LOGO_URL = '/assets/logos/companies/';
export const BASE_CUSTOMER_ICON_URL = '/assets/icons/companies/';

export interface CustomerBucket {
  trend: number | null;
  normalizedScore: number | null;
}

export interface CustomerBucketExtraField extends CustomerBucket {
  fieldDefinitionId: number;
}

export interface BucketScoreModifier {
  modifiers: { score: number; label: string }[];
  score: number;
}

export interface CustomerBuckets {
  settings: {
    lookupDays: number;
    bandsScoresValues: { lowThreshold: number; highThreshold: number };
  };
  healthScore: number | null;
  healthScoreModifier: BucketScoreModifier;
  engagement: CustomerBucket;
  sentiment: CustomerBucket;
  openItems: CustomerBucket;
  responseTime: CustomerBucket;
  extraFields: { [key: string]: CustomerBucketExtraField };
}

export interface OwnerScore {
  date: string;
  message: string | null;
  score: number;
}

export interface LifecycleEvent {
  id: number;
  eventType: string;
  date: string;
  label: string;
  description?: string;
  color: string;
  customerId: number;
  hasAttachedCommunication: boolean;
  videoCallIds: number[];
}

export interface LifecyclePhase {
  id: number;
  date: string;
  endDate: string | null;
  journey: {
    id: number;
  };
}

export interface LifecycleJourney {
  customerId: number;
  events: {
    week: string;
    events: LifecycleEvent[];
  }[];
  phases: LifecyclePhase[];
}

export type CustomerExtraValue = string | number | boolean | Date | null;

export interface CustomerExtraValues {
  [key: string]: CustomerExtraValue;
}

export interface CustomerExtraValueOvertime {
  week: string;
  [key: string]: CustomerExtraValue;
}

export interface CustomerEssentials {
  customer: { value: number; data: ReportCustomerRefData };
  status: { value: CustomerStatus | null };
}

export interface Customer {
  id: number;
  name: string;
  domains: string[];
  status: CustomerStatus;
  owner: number | null;
  tier: {
    id: number | string;
  } | null;
  journey: {
    id: number | string;
  } | null;
  currency: string | null;
  renewalDate: string | null;
  revenue: number | null;
  extraValues: CustomerExtraValues;
  crmId: string | null;
  appId: string | null;
  parentId: number | null;
}

export interface HydratedCustomer extends Omit<Customer, 'owner'> {
  owner: User | null;
  logo?: string;
  icon?: string;
}

export interface CustomerWithDefinitions extends HydratedCustomer {
  stakeholders: Stakeholder[];
}

export interface CustomerOverview {
  customer: Customer;
  totalActivity: ValueAndTrend;
  lastTouch: string | null;
  lastDmTouch: string | null;
  customerSentiments: Sentiments;
  ownerScore: OwnerScore | null;
  email: {
    totalSent: ValueAndTrend;
    organizationSent: ValueAndTrend;
    customerSent: ValueAndTrend;
    organizationResponseTime: ValueAndTrend;
    customerResponseTime: ValueAndTrend;
    organizationOpenThreads: number;
    customerOpenThreads: number;
  };
  event: {
    total: ValueAndTrend;
    nextEvent: string | null;
  };
  ticket: {
    submittedTickets: ValueAndTrend;
  };
  chat: {
    organizationSent: ValueAndTrend;
    customerSent: ValueAndTrend;
    totalSent: ValueAndTrend;
    organizationResponseTime: ValueAndTrend;
    customerResponseTime: ValueAndTrend;
  };
  buckets: CustomerBuckets | null;
  teamEffortHours: number | null;
  syncsCorrectly: boolean;
  extraValues: { [key: string]: ValueAndTrend };
}

export interface CustomerHistoryData {
  id: number;
  customerId: number;
  effectiveDate?: string;
  updatedAt: string;
  owner: User | null;
  journey: {
    id: number | string;
  };
  tier: {
    id: number | string;
  };
  revenue: number | null;
  renewalDate: string | null;
  status: CustomerStatus;
  extraValues: CustomerExtraValues;
}

export interface CustomerSummaryData {
  data: {
    summary: string;
    lastReceivedAt: string;
    emailIds: string[];
  } | null;
  churn: {
    churnDate: string;
    issues: string[];
    synopsis: string;
  } | null;
  renewal: {
    renewalDate: string;
    synopsis: string;
  } | null;
  risk: {
    analysis: string;
    eventDate: string;
  } | null;
}

export interface CustomerDomainSyncProblem {
  domain: string;
  decision: CustomerDomainSyncProblemDecision;
  conflictingCustomers: { name: string; crmId: string }[];
}

export enum CustomerDomainSyncProblemDecision {
  IgnoredInfrequent = 'IgnoredInfrequent',
  ConflictExternal = 'ConflictExternal',
  ConflictInternal = 'ConflictInternal'
}
