import { useCallback, useEffect, useMemo, useState } from 'react';
import { TreeNode } from 'Components/Primitives';
import { ReportRefEntityType } from 'Types/ReportField.types';
import { useCustomersEssentialsQuery, useListsQuery, useUsersEssentialsQuery } from 'hooks';
import { getCustomersTreeSelectOptions } from 'Components/Customers/CustomersTreeSelect/CustomersTreeSelectUtils';
import { getUsersTreeSelectOptions } from 'Components/Users/UsersTreeSelect/UsersTreeSelectUtils';

export const useEntityRefSelectValues = (): { getEntityRefValues(entity: ReportRefEntityType): TreeNode[] } => {
  const [customerSelectList, setCustomerSelectList] = useState<TreeNode[]>([]);
  const [userSelectList, setUserSelectList] = useState<TreeNode[]>([]);
  const [insightSelectList, setInsightSelectList] = useState<TreeNode[]>([]);
  const [lifecycleEventsSelectList, setLifecycleEventsSelectList] = useState<TreeNode[]>([]);

  const { data: customers } = useCustomersEssentialsQuery();
  const { data: users } = useUsersEssentialsQuery();
  const { lists } = useListsQuery();

  const getEntityRefValues = useCallback(
    (entity: ReportRefEntityType): TreeNode[] => {
      const selectLists: Record<ReportRefEntityType, TreeNode[]> = {
        [ReportRefEntityType.Customer]: customerSelectList,
        [ReportRefEntityType.User]: userSelectList,
        [ReportRefEntityType.Insight]: insightSelectList,
        [ReportRefEntityType.LifecycleEvent]: lifecycleEventsSelectList,
        [ReportRefEntityType.Stakeholder]: [],
        [ReportRefEntityType.Participant]: []
      };
      return selectLists[entity];
    },
    [customerSelectList, userSelectList, insightSelectList, lifecycleEventsSelectList]
  );

  useEffect(() => {
    if (!customers) return;
    setCustomerSelectList(getCustomersTreeSelectOptions(customers.data, ''));
  }, [customers]);

  useEffect(() => {
    if (!users) return;
    setUserSelectList(getUsersTreeSelectOptions(users.data, '', 'all'));
  }, [users]);

  useEffect(() => {
    if (!lists) return;
    setInsightSelectList(
      (lists.InsightType || []).map((li) => ({
        value: li.id,
        selectable: true,
        title: li.name,
        searchValue: li.name,
        children: []
      }))
    );
    setLifecycleEventsSelectList(
      (lists.lifecycleEvent || []).map((li) => ({
        value: li.id,
        selectable: true,
        title: li.name,
        searchValue: li.name,
        children: []
      }))
    );
  }, [lists]);

  const res = useMemo(
    (): { getEntityRefValues(entity: ReportRefEntityType): TreeNode[] } => ({
      getEntityRefValues
    }),
    [getEntityRefValues]
  );
  return res;
};
