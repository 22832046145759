import { isValidElement, ReactNode } from 'react';
import setWith from 'lodash/setWith';

export const simpleUID = (prefix?: string, length: number = 5, suffix: string | boolean = true): string => {
  return (
    (isString(prefix) && prefix ? `${prefix.replace(/(^[\W_]|[\W_]$)/g, '').replace(/\s+/g, '_')}-` : '') +
    Math.random().toString(16).substr(2, length) +
    (isString(suffix)
      ? (length ? '-' : '') + suffix.replace(/\s+/g, '_')
      : suffix !== false
        ? (length ? '-' : '') + String(new Date().getTime()).slice(-4)
        : '')
  );
};

export const nonNullable = <T>(value: T): value is NonNullable<T> => value !== null && value !== undefined;

export const kebabCase = (str: string) => str.split(' ').join('-').toLowerCase();
export const camelCase = (str: string) => str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (_, chr) => chr.toUpperCase());

export const camelCaseToKebabCase = (str: string) =>
  str
    .split('')
    .map((l, i) => (l.toUpperCase() === l ? `${i !== 0 ? '-' : ''}${l.toLowerCase()}` : l))
    .join('');

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const isString = (val: any): val is string => typeof val === 'string';

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const isNumber = (val: any): val is number => typeof val === 'number' && isFinite(val);

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const isJsonString = (str: any): boolean => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const formatBytes = (bytes: number, decimals = 2): string => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

//
// This method is taken from stack overflow: https://stackoverflow.com/a/13627586
//
export const getOrdinalSuffix = (num: number): string => {
  const j = num % 10;
  const k = num % 100;
  if (j === 1 && k !== 11) {
    return `${num}st`;
  }
  if (j === 2 && k !== 12) {
    return `${num}nd`;
  }
  if (j === 3 && k !== 13) {
    return `${num}rd`;
  }
  return `${num}th`;
};

export const formatNumberToShortString = (value: number, numOfDigits: number): string => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' }
  ];

  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find((i) => {
      return value >= i.value;
    });
  return item ? (value / item.value).toFixed(numOfDigits).replace(rx, '$1') + item.symbol : '0';
};

// taken from https://github.com/sunknudsen/react-node-to-string/blob/master/src/index.ts
export const reactNodeToString = function (reactNode: ReactNode): string {
  let string = '';
  if (typeof reactNode === 'string') {
    string = reactNode;
  } else if (typeof reactNode === 'number') {
    string = reactNode.toString();
  } else if (reactNode instanceof Array) {
    reactNode.forEach(function (child) {
      string += reactNodeToString(child);
    });
  } else if (isValidElement(reactNode)) {
    string += reactNodeToString((reactNode.props as { children?: ReactNode })?.children);
  }
  return string;
};

// Function taken from this Stack Overflow answer:
// https: stackoverflow.com/a/9229821
export const uniqFast = <T extends number | string>(a: T[]): T[] => {
  var seen: { [key: string]: number } = {};
  var out: T[] = [];
  var len = a.length;
  var j = 0;
  for (var i = 0; i < len; i++) {
    var item = a[i];
    if (seen[item] !== 1) {
      seen[item] = 1;
      out[j] = item;
      j = j + 1;
    }
  }
  return out;
};

//
//
//

type ObjectType = { [key: string]: any };

export const flattenObject = (obj: ObjectType, parent?: string): ObjectType => {
  let res: any = {};
  for (const [key, value] of Object.entries(obj)) {
    const propName: string = parent ? parent + '.' + key : key;
    if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
      res = { ...res, ...flattenObject(value, propName) };
    } else {
      res[propName] = value;
    }
  }

  return res;
};

//
//
//

export const setObjectFromKeys = (obj: ObjectType): ObjectType =>
  Object.entries(obj).reduce((acc: ObjectType, [key, value]) => {
    setWith(acc, key, value, Object);
    return acc;
  }, {});

//
//
//

export const getObjectWithoutNullValues = (obj: ObjectType): ObjectType => {
  if (Array.isArray(obj)) {
    return obj
      .map((item) => (item !== null && typeof item === 'object' ? getObjectWithoutNullValues(item) : item))
      .filter((item) => item !== null);
  }
  return Object.keys(obj).reduce((acc: ObjectType, key: string) => {
    if (nonNullable(obj[key])) {
      if (typeof obj[key] === 'object') {
        const nestedResult = getObjectWithoutNullValues(obj[key]);
        if (Object.keys(nestedResult).length > 0) {
          acc[key] = nestedResult;
        }
      } else {
        acc[key] = obj[key];
      }
    }
    return acc;
  }, {});
};

//
//
//

export const extractNumberFromString = (str: string): number | null => {
  const numberAsString = str.replace(/[^0-9.]/g, '');
  if (!isNaN(parseFloat(numberAsString)) && numberAsString.split('.').length <= 2) {
    return parseFloat(numberAsString);
  }
  return null;
};

//
//
//

export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
